.header
    min-height 700px
    height 100vh
    background url('lead.jpg') no-repeat top center fixed;
    background-size: cover;

    +below('m')
        width: 100%;
        min-height auto
        background: url(lead.jpg) 0px 88px no-repeat;
        background-size: contain;
        display: block;
        height: 61vh

    +below('xs')
        height 260px

    .wrapper
        height 100%

    &--small
        background-image: url('lead--small.jpg')
        background-size: cover;
        height 25vh
        background-position center 35%
        min-height 320px

        +below('s')
            min-height 180px
