.cta
    background url('cta_background.jpg') no-repeat top center;
    background-size: cover;
    color #fff
    padding 2em 0 2.5em
    lost-utility clearfix
    text-align center

    &__text
        lost-column 1/1
        margin-bottom 2em

    &__actions
        lost-column 1/1

    h2
        margin-top 0
        margin-bottom 0
        line-height 1.0
        font-size 2.0rem

    p
        margin 0
        line-height 1.4
        font-size 1rem
        font-weight 300

    &__button
        margin-top 1.3em
        padding 0.8em 2em
        font-family 'Montserrat',Helvetica,Arial,sans-serif;

        &.btn--bordered
            color white
            border 1px solid white
            &:hover
                color primary-color

        +below('m')
            display block !important
            margin 0

            + .btn
                margin-top .5em
                margin-left 0 !important
