.header
    line-height 1

    h1
        color white
        font-size 2.5rem
        text-transform uppercase
        top 22%
        position absolute
        +below('s')
            top 0%

.topbar
    background white
    height 100px

.logo
    lost-column 3/12
    height 100%
    +below('m')
        lost-column 2/3
    +below('s')
        lost-column 2/3

    a
        color inherit
        display block
        padding 1.75em 0

    img
        max-width 100%