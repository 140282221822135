.coupon
  text-align left
  &__form
    lost-column 2/3
    margin-right 2em
    +below('m')
      lost-column 1/1

  &__condition
    lost-column 1/3
    +below('m')
      lost-column 1/1

  .form-group
    lost-column 1/1
    &__small
      lost-column 1/4
      +below('xs')
        lost-column 2/5

    &__big
      lost-column 3/4
      +below('xs')
        lost-column 3/5

  #form .btn
    border 1px solid btn-border-color
    background #fff
    border-radius 0px
    color inherit
    text-align center
    padding .4em 1em
    font-size 1rem
    outline none
    transition .2s ease-out
    transition-property border-color, color, background, box-shadow, transform
    display inline-block
    line-height 1
    box-shadow 0 2px 4px rgba(0,0,0,.1)
    +below('l')
      margin-bottom 1.5rem
    +below('s')
      lost-column 1/1

    &:hover
      box-shadow 0 2px 12px rgba(0,0,0,.25)

    &:active
      transform scale(.98)
