.footer
    border-bottom 7px solid primary-color
    .nav-link
        padding 2.3em 0.8em
        margin-bottom 0em
        border none !important
    .logo a
        padding 1.5em 0 1.3em
        +below('s')
            padding 1.8em 0 1.3em

