.nav-main
    lost-column 9/12
    text-align right
    text-transform uppercase
    +below('m')
        lost-column 1/3
    +below('s')
        lost-column 1/3
    +below('xs')
        lost-column 1/3

    .nav-items
        +below('l')
            display none

.nav-items
    margin 0
    padding 0

.nav-main .nav-item
    display inline-block
    padding 0
    margin 0
    position relative
    &:first-child
        margin-left 0

    +below('m')
        margin 0 1em

    &--active > .nav-link,
    &--child-active > .nav-link
        color primary-color

    &:hover .nav-sub
        display block


.nav-link
    display block
    color inherit
    font-weight 300
    padding 2.9em .8em 1em
    margin-bottom 1.5em
    font-size 0.9rem
    &:hover
        border-bottom 2px solid primary-color

.nav-main .nav-sub
    position absolute
    z-index 1
    padding-left 0
    display none
    left 0
    top 51px
    min-width 250px
    box-shadow 0 2px 6px rgba(0, 0, 0, .1)
    background #fff
    .nav-item
        display block
        margin 0

    .nav-link
        display block
        margin 0
        padding .8em
