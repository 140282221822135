.main
    lost-utility clearfix
    padding 4em 0
    +below('s')
        padding 2em 0
    color primary-color
    min-height 400px
    min-height 40vh

.content
    lost-column 1/1
    font-size 1rem
    line-height 1.7
    margin-top .5em

    h1
        font-size 2.5rem
        text-transform uppercase

    p
        margin 0 0 1em

    .left-column h1
        lost-column 1/2
        line-height 1
        margin-top 0
        font-weight 400
        +below('s')
            lost-column 1/1


    .right-column
        lost-column 1/2
        +below('s')
            lost-column 1/1

    a
        border-bottom: 1px black dotted;
        &:hover
            border-bottom: 1px black solid