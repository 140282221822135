ul.agenda
  padding 0
  margin 0
  li
    lost-column 1/1
    list-style none
    padding 0
    margin 0
    margin-bottom 2em

.agenda-entry
  &__text
    margin-bottom 0.5rem
    min-height 145px
    lost-column 4/5
  &__date
    lost-column 1/5
    +below('m')
      margin-top 0.5rem
    +below('s')
      lost-column 1/1
      padding-bottom 2em
      border-top 3px solid primary-color
    font-family $font-family-secondary
    .day
      font-weight 800
      font-size 8rem
      display block
      line-height 0.9
      +below('m')
        font-size 6rem
    .month
      font-size 2.5rem
      text-align center
      line-height 1
      +below('m')
        font-size 1.5rem

.agenda-small
  background primary-color
  padding 2em 0 0.5em
  border-color #fff
  a
    color #fff
    border-color #fff
  .agenda-entry__date
    border-color #fff

.info-bar
  border 1px solid primary-color
  padding 0.5em 1em
  margin 0 0 2em
  display inline-block
  font-family $font-family-secondary
  font-weight 300
  i
    padding 0 0.5em 0 1.5em
    &:first-child
      padding-left 0

.backlink
  text-align right
  +below('s')
    display none