ul.music
  padding 0
  margin 0
  li.music-entry
    lost-column 1/1
    list-style none
    padding 0
    margin 0
    margin-bottom 2em

    ol
      padding-left: 18px;

.music-entry
  &__image
    lost-column 1/3
    +below('s')
      lost-column 1/1
    img
      max-width 100%
  &__content
    lost-column 1/1
    &.small
      lost-column 2/3
      +below('s')
        lost-column 1/1

.music-small
  background primary-color
  padding-top 2em
  a
    color #fff
    border-color #fff

.info-bar
  border 1px solid primary-color
  padding 0.5em 1em
  margin 0 0 2em
  display inline-block
  font-family $font-family-secondary
  font-weight 300
  i
    padding 0 0.5em 0 1.5em
    &:first-child
      padding-left 0






